<template>
<!--  <v-navigation-drawer-->
<!--      app-->
<!--      fixed-->
<!--      bottom-->
<!--      color="transparent"-->
<!--      height="auto"-->
<!--      overlay-color="secondary"-->
<!--      overlay-opacity=".8"-->
<!--      temporary-->
<!--      v-bind="$attrs"-->
<!--      v-on="$listeners"-->
<!--  >-->

  <v-navigation-drawer
      app
      fixed
      temporary
      v-bind="$attrs"
      v-on="$listeners"
      color="white"
  >

    <base-img
          :src="require('@/assets/logo-tdf.jpg')"
          contain
           height="80"
           width="200"
        />

    <v-divider class="mb-1" />

    <v-list
        nav
    >
      <v-list-item
          v-for="menu in items"
          :key="menu.page"
          :to="{name : menu.page}"
          color="primary"
      >
        <v-list-item-content>
          <v-list-item-title v-text="menu.text" />
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'HomeDrawer',

  props: {
    items: {
      type: Array,
      default: () => ([]),
    },
  },
}
</script>

<style lang="sass">

#core-navigation-drawer
  .v-list-group__header.v-list-item--active:before
    opacity: .24

  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px


  .v-list--dense
    .v-list-item
      &__icon--text,
      &__icon:first-child
        margin-top: 10px

  .v-list-group--sub-group
    .v-list-item

    .v-list-group__header

      .v-list-item__icon--text
        margin-top: 19px
        order: 0

      .v-list-group__header__prepend-icon
        order: 2
#core-navigation-drawer
  .v-list-group__header.v-list-item--active:before
    opacity: .24

  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px


  .v-list--dense
    .v-list-item
      &__icon--text,
      &__icon:first-child
        margin-top: 10px

  .v-list-group--sub-group
    .v-list-item

    .v-list-group__header

      .v-list-item__icon--text
        margin-top: 19px
        order: 0

      .v-list-group__header__prepend-icon
        order: 2

</style>
